@tailwind base;
@tailwind components;
@tailwind utilities;


.img-main-div {
    position: absolute;
    top: 0;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -2;
}

.navbar-spacing{
    margin: 12rem;
    margin-top: 0;
    margin-bottom: 0
}

.wd-matin-title {
    font-size: 2.7vw;
}

.wd-navbar-title {
    font-size: 1.7vw;
    margin-left: 1.2%;
    margin-top: .3vh; 
}
  
.move-up{
    margin-top: 12%
}

.wd-main-title {
    font-size: 1.3vw;
    margin-top: 2%;
}


.join-discord {
    text-decoration: none;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 30px;
    padding-top: 14px;
    padding-left: 30px;
    padding-bottom: 14px;
    padding-right: 35px;
    font-size: 13px;
    background-color: #070911;
    color:white;
    float:right;
    font-weight: 600;
    position: relative;
    transition: .3s;
    filter: invert(1);
} 

.join-discord:hover {
    padding-right: 39px;
    transition: .3s;
    box-shadow: 1px 1px 5px rgb(190, 190, 190);
}

.join-discord-css-button-move{
    margin-left:5px;
    transition: .3s;
}

.join-discord:hover .join-discord-css-button-move{
    margin-left:1px;
    transition: .3s;
}

.arrow-icon-2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    margin-left:5px;
}

.join-discord:hover .arrow-icon-2 {
    opacity: 1;
}


.get-notififed {
    text-decoration: none;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 30px;
    padding-top: 13px;
    padding-left: 30px;
    padding-bottom: 13px;
    padding-right: 35px;
    font-size: 14px;
    color:black;
    background-color: white;
    font-weight: 600;
    position: relative;
    transition: .3s;
    filter: invert(1);
}

.navbarbsifg {
    position: fixed;
    width: 100%;
    backdrop-filter: blur(30px);
}

.navbarbsifg-two-scroll-down{
    position: fixed;
    width: 100%;
    backdrop-filter: blur(30px);
    z-index: 999;
}

.get-notififed:hover {
    padding-right: 39px;
    transition: .3s;
    box-shadow: 1px 1px 5px rgb(190, 190, 190);
}

.Get-Notifed-css-button-move{
    margin-left:5px;
    transition: .3s;
}

.get-notififed:hover .Get-Notifed-css-button-move{
    margin-left:1px;
    transition: .3s;
}

.arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    margin-left:5px;
}

.get-notififed:hover .arrow-icon {
    opacity: 1;
}
  
.logo {
    max-width: 6%; 
    height: auto; 
    object-fit: contain; 
}

.hidewhenscreensmall{
    display: block;
}

@media all and (max-width: 1604px) {
    .navbar-spacing{
        margin: 10rem;
        margin-top: 0;
        margin-bottom: 0
    }
    @media all and (max-width: 1430px) {
        .navbar-spacing{
            margin: 7rem;
            margin-top: 0;
            margin-bottom: 0
        }
    }
    @media all and (max-width: 968px) {
        .navbar-spacing{
            margin: 5rem;
            margin-top: 0;
            margin-bottom: 0
        }
    }
    @media all and (max-width: 465px) {
        .wd-matin-title {
            font-size: 4.5ch;
            margin-bottom: 40px;
        
        }

        .wd-matinn-title {
            font-size: 2ch;
            margin-bottom: 40px;
        
        }

        .wd-makeonsmall{
            margin-top: 40px;
            padding: 0;
        }
    
        .wd-navbar-left {
            display: none;
        }
    
        .wd-navbar-title {
            margin-top: 7px;
            font-size: 2.5ch;
        }

        .wdssprint-logo {
            display: none;
        }
    
        .wd-main-title {
            font-size: 3ch;
            margin-top: 20%;
            margin-right: 10%;
            margin-left: 10%;
            margin-bottom: 20%;
        }

        @media all and (max-width: 425px) {
            .hidewhenscreensmall{
                display:none;
            }
        }
        
    }
}
