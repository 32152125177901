@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: black;
    filter: invert(1);
}
::selection {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
